<template>
  <div>
    <div class="cards">
      <div class="cards-container row d-flex wrap align--start">
        <div
          class="flex xs12 sm6 lg4 xl3 manager-card"
          v-for="item in retailerManagerLicenses"
          :key="item.id"
          @click.prevent="onChangeLicense(item)"
        >
          <vac-card
            stripe="info"
            :title="item.name"
          >
            <div class="cards__body">
              <span>
                problems
              </span>
              <div class="cards__percentage">
<!--                <VacChart :data="calcChartData(item)" :options="chartOptions" type="donut"/>-->
                <Doughnut
                  :dataset-id-key="'label'"
                  :height="70"
                  :chart-options="chartOption"
                  :chart-data="calcChartData(item)"
                  :style="{height: '70px'}"
                />

                <span class="text">{{ item.quantityReasonedEpackageRetailers }}</span>
              </div>
            </div>
          </vac-card>
        </div>
      </div>
    </div>
    <ModalRetailTable @close-modal="closeModal" v-if="activeModal" :selectedRetailer="selectedRetailer"/>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import VacChart from "../../components/VacChart";
import Chart from './mixins/chart';
import {bus} from "../../app/main";
import { Doughnut } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)


const user = JSON.parse(localStorage.getItem('ttlUserInfo'));

export default {
  name: 'RetailManager',

  computed: {
    ...mapState(['retailerManagerLicenses']),
    chartOption() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '60%',
        plugins: {
          tooltip: {
            enabled: false,
            external: function(context) {
              // Tooltip Element
              let tooltipEl = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              const tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                const titleLines = tooltipModel.title || [];
                const bodyLines = tooltipModel.body.map(getBody);

                let innerHtml = '<thead>';

                titleLines.forEach(function(title) {
                  innerHtml += '<tr><th>' + title + '</th></tr>';
                });
                innerHtml += '</thead><tbody>';

                bodyLines.forEach(function(body, i) {
                  const colors = tooltipModel.labelColors[i];
                  let style = 'background:' + colors.backgroundColor;
                  style += '; border-color:' + colors.borderColor;
                  style += '; border-width: 2px';
                  const span = '<span style="' + style + '"></span>';
                  innerHtml += '<tr><td>' + span + body + '</td></tr>';
                });
                innerHtml += '</tbody>';

                let tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
              }

              const position = context.chart.canvas.getBoundingClientRect();
              // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.background = 'rgba(0, 0, 0, 0.5)';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              // tooltipEl.style.font = bodyFont.string;
              tooltipEl.style.fontSize = '14px';
              tooltipEl.style.color = '#FFFFFF'
              tooltipEl.style.borderRadius = '5px'
              tooltipEl.style.padding = '5px';
              tooltipEl.style.pointerEvents = 'none';
            }
          },
          legend: {
            display: false
          }
        },
        scales: {
          x: {display: false},
        },
      }
    },
  },

  mixins: [Chart],

  async created() {
    bus.$emit('set-header-loading', true)
    await this.getRetailManagerLicenses();
    bus.$emit('set-header-loading', false)
  },
  components: {
    ModalRetailTable: () => import('../../components/retail-manager/components/ModalRetailTable.vue'),
    Doughnut
  },
  data() {
    return {
      activeModal: false,
      selectedRetailer: null,
    };
  },
  methods: {
    ...mapActions(['getRetailManagerLicenses']),
    onChangeLicense(item) {
      this.activeModal = true;
      this.selectedRetailer = item;
    },
    closeModal() {
      this.activeModal = false;
      this.selectedRetailer = null;
    },
  },
};
</script>

<style scoped lang="scss">
.cards {
  &__body {
    display: flex;
    align-items: center;

    a {
      display: block;
      margin-right: 10px;
    }
  }

  &__percentage {
    width: 70px;
    height: 70px;
    z-index: 1;
    position: relative;
    margin-left: auto;

    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
    }
  }
}
</style>
