export default {
  data() {
    return {
      chartOptions: {
        cutoutPercentage: 60,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
          caretSize: 0,
          fontSize: 10,
          custom: function (tooltipModel) {
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<table></table>';
              tooltipEl.style.backgroundColor = "rgba(0,0,0,.5)";
              tooltipEl.style.borderRadius = "4px";
              tooltipEl.style.border = "none";
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              let titleLines = tooltipModel.title || [];
              let bodyLines = tooltipModel.body.map(getBody);

              let innerHtml = '<thead>';

              titleLines.forEach(function(title) {
                innerHtml += '<tr><th>' + title + '</th></tr>';
              });
              innerHtml += '</thead><tbody>';

              bodyLines.forEach(function(body, i) {
                let colors = tooltipModel.labelColors[i];
                let style = 'background: rgba(0,0,0,.25)';
                style += '; border-color: transparent';
                style += '; border-width: 1px';
                let span = '<span style="' + style + '"></span>';
                innerHtml += '<tr><td>' + span + body + '</td></tr>';
              });
              innerHtml += '</tbody>';

              let tableRoot = tooltipEl.querySelector('table');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            let position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = '1';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.color = '#fff';
            tooltipEl.style.border = 'none';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.fontWeight = '600';
          },
        },
        scales: {
          xAxes: [{display: false}],
        },
      }
    }
  },

  methods: {
    calcChartData(item) {
      return {
        datasets: [
          {
            borderColor: "transparent",
            backgroundColor: ['#000', '#a50034', '#f3972b',
              '#D4A7B8', '#8B2171', '#383083', '#518330'],
            label: 'Problems',
            data: [item.reason['1'], item.reason['2'], item.reason['3'],
              item.reason['4'], item.reason['5'], item.reason['6'], item.reason['7']],
          },
        ],
        labels: ['Incorrect ean', 'No script', 'Incorrect script', 'No brand', 'Incorrect brand', 'Incorrect domain', 'No ean'],
      };
    }
  }
}
